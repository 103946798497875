.layout main {
  padding-top: 10px;
}

.manufacturers_image img {
  max-width: 50px;
}

.articles_image img {
  width: 250px;
  height: 150px;
}

.series_image img {
  max-width: 50px;
  border-radius: 5px;
}
